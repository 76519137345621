.buttonFooter {
    position: fixed;
    right: 80px;
    bottom: 100px;
    z-index: 1;
}

.heading {
    text-align: center;
    font-family: "rubik";
    text-shadow: 2px 2px 4px var(--black);
    font-size: 75px;
    margin-bottom: 0px;
    color: var(--tertiary);
    background-color: var(--secondary);
}