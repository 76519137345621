:root {
    --primary: #660000;
    --secondary: #666060;
    --tertiary: #c5c3c3;
    --black: #000000;
    --white: #FFFFFF;
}

.containerBody {
    min-height: calc(100vh - 56px - 192px);
    display: flex;
    flex-direction: column;
}

.footer {
    position: absolute;
    width: 100%;
}

.logo-font {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    letter-spacing: 0.1em;
    font-weight: bold;
}

.center-content {
    display: flex;
    justify-content: center;
}

.center {
    text-align: center
}

.heading {
    text-align: center;
    font-family: "rubik";
    text-shadow: 2px 2px 4px var(--black);
    font-size: 75px;
    margin-bottom: 0px;
    color: var(--tertiary);
    background-color: var(--secondary);
}

.myButton {
    box-shadow: 0px 1px 4px 1px grey !important;
    font-size: 20px;
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
}

.myButton:hover{
    transform: scale(1.05);
    background-color: var(--secondary);
    border-color: var(--secondary);
    box-shadow: 1 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.hidden-links {
    text-decoration: none;
    color: inherit;
}

.nowrap {
    white-space: nowrap
}

.color-primary { color: var(--primary) !important}
.color-secondary { color: var(--secondary) !important}
.color-tertiary { color: var(--tertiary) !important}
.color-black { color: var(--black) !important}
.color-white { color: var(--white) !important}

.bg-color-primary { background-color: var(--primary) !important }
.bg-color-secondary { background-color: var(--secondary) !important }
.bg-color-tertiary { background-color: var(--tertiary) !important }
.bg-color-black { background-color: var(--black) !important }
.bg-color-white { background-color: var(--white) !important }

.border-color-primary { border-color: var(--primary) !important }
.border-color-secondary { border-color: var(--secondary) !important }
.border-color-tertiary { border-color: var(--tertiary) !important }
.border-color-black { border-color: var(--black) !important }
.border-color-white { border-color: var(--white) !important }